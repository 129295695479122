
/**
 * @name: 礼品卡管理-礼品卡查询
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡查询
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import { cardListQueryApi, cardListExportApi, cardListDetailApi } from "@/apis/card/list"
import { ICardList, ICardListParam, ICardSale } from "@/apis/card/list/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class cardList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICardList[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICardListParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 130,
    viewTitle: "卡号消费明细",
    dialogWidth: '600px',
    column: [
      {
        "label": "卡号",
        "prop": "cardNumber",
        "align": "left",
        "width": 150,
        "search": true,
        "slot": true,
        "viewSlot": true
      },
      {
        "label": "生成批次ID",
        "prop": "giftCouponId",
        "align": "center",
        "width": 180,
        "search": true,
        "editHide": true,
        "addHide": true,
        "viewHide": true
      },
      {
        "label": "礼品卡类别",
        "prop": "giftCategoryName",
        "align": "center",
        "overHidden": true,
        "search": true,
        "hide": false,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "面值(元)",
        "prop": "denomination",
        "align": "center",
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "余额",
        "prop": "balancePrice",
        "align": "center",
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "绑定账号",
        "prop": "userPhone",
        "align": "center",
        "overHidden": false,
        "width": 150,
        "search": true,
        "editHide": true,
        "addHide": true,
        "viewHide": true
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "width": 100,
        "type": "select",
        "search": true,
        "addHide": true,
        "editHide": true,
        "dicData": [
          {
            label: "未激活",
            value: 1
          },
          {
            label: "已激活",
            value: 2
          },
          {
            label: "已使用",
            value: 3
          },
          {
            label: "已用完",
            value: 4
          },
          {
            label: "已过期",
            value: 5
          }
        ],
        "viewHide": true
      },
      {
        "label": "激活时间",
        "prop": "activateTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "有效期至",
        "prop": "expirationTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "生成时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "addHide": true,
        "editHide": true,
        "search": true,
        "viewHide": true
      },
      {
        "label": "使用完成时间",
        "prop": "useFinishTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      }
    ]
  }

  cardSaleList: ICardSale[] = []

  getList () {
    this.tableLoading = true
    const query: ICardListParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.expirationTime && query.expirationTime.length) {
      query.expirationStartTime = query.expirationTime[0]
      query.expirationEndTime = query.expirationTime[1]
    } else {
      query.expirationStartTime = ''
      query.expirationEndTime = ''
    }
    delete query.expirationTime

    if (query.activateTime && query.activateTime.length) {
      query.activateStartTime = query.activateTime[0]
      query.activateEndTime = query.activateTime[1]
    } else {
      query.activateStartTime = ''
      query.activateEndTime = ''
    }
    delete query.activateTime

    if (query.useFinishTime && query.useFinishTime.length) {
      query.useFinishStartTime = query.useFinishTime[0]
      query.useFinishEndTime = query.useFinishTime[1]
    } else {
      query.useFinishStartTime = ''
      query.useFinishEndTime = ''
    }
    delete  query.useFinishTime
    cardListQueryApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  exportExcel () {
    const query: ICardListParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.expirationTime && query.expirationTime.length) {
      query.expirationStartTime = query.expirationTime[0]
      query.expirationEndTime = query.expirationTime[1]
    } else {
      query.expirationStartTime = ''
      query.expirationEndTime = ''
    }
    delete query.expirationTime

    if (query.activateTime && query.activateTime.length) {
      query.activateStartTime = query.activateTime[0]
      query.activateEndTime = query.activateTime[1]
    } else {
      query.activateStartTime = ''
      query.activateEndTime = ''
    }
    delete query.activateTime
    cardListExportApi(query).then(e => {
      exportFile(e, "礼品卡查询.xlsx");
    })
  }

  viewSaleDetail (row: ICardList, index: number) {
    cardListDetailApi(row.cardNumber).then(e => {
      this.cardSaleList = e;
      // @ts-ignore
      this.$refs.crudRef.rowView(row, index)
    })
  }

  created () {
    this.getList()
  }
}
